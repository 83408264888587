import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'simplepay',
		component: () =>
			import(
				/* webpackPreload: true */ /* webpackChunkName: "simplepay" */ '@/views/SimplePayHostedPaymentView.vue'
			)
	},
	{
		path: '/login',
		name: 'login',
		component: () =>
			import(
				/* webpackChunkName: "admin-login" */ '@/views/SimplePayLoginView.vue'
			)
	},
	{
		path: '/admin',
		name: 'admin',
		component: () =>
			import(
				/* webpackChunkName: "admin-list" */ '@/views/AdminSimplePayListView.vue'
			),
		beforeEnter: (to, from, next) => {
			const loggedIn = sessionStorage.getItem('isLoggedIn')
			loggedIn && loggedIn === 'true' ? next() : next('/login')
		}
	},
	{
		path: '/admin/create',
		name: 'adminCreate',
		component: () =>
			import(
				/* webpackChunkName: "admin-create" */ '@/views/AdminSimplePayCreateView.vue'
			),
		beforeEnter: (to, from, next) => {
			const loggedIn = sessionStorage.getItem('isLoggedIn')
			loggedIn && loggedIn === 'true' ? next() : next('/login')
		}
	},
	{
		path: '/admin/edit/:id',
		name: 'adminEdit',
		component: () =>
			import(
				/* webpackChunkName: "admin-edit" */ '@/views/AdminSimplePayEditView.vue'
			),
		beforeEnter: (to, from, next) => {
			const loggedIn = sessionStorage.getItem('isLoggedIn')
			loggedIn && loggedIn === 'true' ? next() : next('/login')
		}
	},
	{
		path: '/404',
		name: 'sp-page-not-found',
		component: () =>
			import(
				/* webpackPreload: true */ /* webpackChunkName: "404" */ '@/views/SimplePayPageNotFoundView.vue'
			)
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
