import { GroupProperty, PeopleProperty } from '@/types/mixpanel'
import mixpanel from 'mixpanel-browser'

/* eslint-disable */

/*

___  ____                             _   _____                _                           _        _   _             
|  \/  (_)                           | | |_   _|              | |                         | |      | | (_)            
| .  . |___  ___ __   __ _ _ __   ___| |   | | _ __ ___  _ __ | | ___ _ __ ___   ___ _ __ | |_ __ _| |_ _  ___  _ __  
| |\/| | \ \/ / '_ \ / _` | '_ \ / _ \ |   | || '_ ` _ \| '_ \| |/ _ \ '_ ` _ \ / _ \ '_ \| __/ _` | __| |/ _ \| '_ \ 
| |  | | |>  <| |_) | (_| | | | |  __/ |  _| || | | | | | |_) | |  __/ | | | | |  __/ | | | || (_| | |_| | (_) | | | |
\_|  |_/_/_/\_\ .__/ \__,_|_| |_|\___|_|  \___/_| |_| |_| .__/|_|\___|_| |_| |_|\___|_| |_|\__\__,_|\__|_|\___/|_| |_|
              | |                                       | |                                                           
              |_|                                       |_|                                                           

*/
// will opt user out of tracking
export function optUserOut() {
	mixpanel.opt_out_tracking()
	mixpanel.people.delete_user()
}

/***** People Properties *****/

// called when a user logs in with a user name and password
export function loginSetPeopleProp(user: PeopleProperty) {
	mixpanel.people.set_once({
		admin_username: user.admin_username,
		admin_user: user.admin_user
	})
	mixpanel.people.set({
		$name: user.name
	})
}

// called when the user submits a transaction and tracks their amount paid to the user and the revenue report in mixpanel
export function cardFormSetPeopleProp(user: PeopleProperty) {
	mixpanel.people.set({
		$name: user.name,
		$email: user.email
	})
	mixpanel.people.track_charge(user.amount, {
		$time: new Date()
	})
	mixpanel.people.increment('Amount Paid', user.amount)
}

// called when the user submits a transaction and tracks their amount paid to the user and the revenue report in mixpanel
export function achFormSetPeopleProp(user: PeopleProperty) {
	mixpanel.people.set({
		$name: user.name,
		$email: user.email
	})
	mixpanel.people.track_charge(user.amount, {
		$time: new Date()
	})
	mixpanel.people.increment('Amount Paid', user.amount)
}

// called when the user searches by a keyword in the admin list and adds an arUserray of searched values
export function searchedValues(search: Array<string>) {
	mixpanel.people.append({ 'Searched Values': search })
}

// called when user views card form and increments their views
export function cardViewCountPeopleProp() {
	mixpanel.people.increment('Card Form View Count', 1)
}

// called when user views ach form and increments their views
export function achViewCountPeopleProp() {
	mixpanel.people.increment('Ach Form View Count', 1)
}

// called when user views a inactive simplepay page and increments their views
export function inactiveViewCountPeopleProp() {
	mixpanel.people.increment('Inactive Form View Count', 1)
}

// called when user views the admin list and increments their views
export function adminListViewCountPeopleProp() {
	mixpanel.people.increment('Admin List Page View', 1)
}

// called when user views the edit page of a sp page and increments their views
export function adminEditViewCountPeopleProp() {
	mixpanel.people.increment('Admin Edit List Page View', 1)
}
// called when the user saves the page
export function adminEditSavedCountPeopleProp() {
	mixpanel.people.increment('Admin Edit Saved Change', 1)
}

// called when user views the payment success page and increments their views
export function successViewCountPeopleProp() {
	mixpanel.people.increment('Success Page View', 1)
}

// called if user is redirect to another page after payment other than success page
export function redirectCountPeopleProp() {
	mixpanel.people.increment('Redirect after payment', 1)
}

// called if user has successful payment and increments their view
export function paymentSuccessPeopleCount() {
	mixpanel.people.increment('Payment Success Count', 1)
}

// called if a user gets a declined payment and increments their declined count
export function paymentDeclinePeopleCount() {
	mixpanel.people.increment('Payment Declined Count', 1)
}

// called if a user gets a processing error and increments their processing error count
export function paymentProcessingErrorPeopleCount() {
	mixpanel.people.increment('Processing Error Count', 1)
}

// called if a user creates a page and increments their page creation count
export function createdPagePeopleCount() {
	mixpanel.people.increment('New SimplePay Page Created', 1)
}

// called if a user deactivates a page and increments their count
export function deactivatePagePeopleCount() {
	mixpanel.people.increment('User deactivated page', 1)
}

// called if a user activates a page and increments their count
export function activatePagePeopleCount() {
	mixpanel.people.increment('User activated page', 1)
}

// called if a user activates a page and increments their count
export function spEditPropChangedPeopleProp(changedSettings: any) {
	if (changedSettings.secretApiKey) {
		mixpanel.people.set({
			secret_api_key_admin_settings_changed_value:
				changedSettings.secretApiKey
		})
	}
	if (changedSettings.merchantId || changedSettings.merchantId == '') {
		mixpanel.people.set({
			merchantId_admin_settings_changed_value: changedSettings.merchantId
		})
	}
	if (changedSettings.simplePaySettings.merchantName) {
		mixpanel.people.set({
			merchant_name_admin_settings_changed_value:
				changedSettings.simplePaySettings.merchantName
		})
	}
	if (changedSettings.simplePaySettings.cardProcessorId) {
		mixpanel.people.set({
			cardProcessorId_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardProcessorId
		})
	}
	if (changedSettings.simplePaySettings.achProcessorId) {
		mixpanel.people.set({
			achProcessorId_admin_settings_changed_value:
				changedSettings.simplePaySettings.achProcessorId
		})
	}

	if (typeof changedSettings.simplePaySettings.hasCardForm !== 'undefined') {
		mixpanel.people.set({
			hasCardForm_admin_settings_changed_value:
				changedSettings.simplePaySettings.hasCardForm
		})
	}
	if (typeof changedSettings.simplePaySettings.hasAchForm !== 'undefined') {
		mixpanel.people.set({
			hasAchForm_admin_settings_changed_value:
				changedSettings.simplePaySettings.hasAchForm
		})
	}
	if (changedSettings.simplePaySettings.achProcessing) {
		mixpanel.people.set({
			achProcessing_admin_settings_changed_value:
				changedSettings.simplePaySettings.achProcessing
		})
	}
	// if (changedSettings.simplePaySettings.acceptedCards) {
	// 	const containsMaster =
	// 		changedSettings.simplePaySettings.acceptedCards.includes(
	// 			'MasterCard'
	// 		)
	// 	console.log(containsMaster)

	// 	const containsDiscover =
	// 		changedSettings.simplePaySettings.acceptedCards.includes('Discover')
	// 	console.log(containsDiscover)

	// 	const containsVisa =
	// 		changedSettings.simplePaySettings.acceptedCards.includes('Visa')
	// 	console.log(containsVisa)

	// 	const containsAE =
	// 		changedSettings.simplePaySettings.acceptedCards.includes(
	// 			'Amercian Express'
	// 		)
	// 	console.log(containsAE)
	// }

	if (changedSettings.simplePaySettings.primaryColor) {
		mixpanel.people.set({
			primaryColor_admin_settings_changed_value:
				changedSettings.simplePaySettings.primaryColor
		})
	}
	if (changedSettings.simplePaySettings.secondaryColor) {
		mixpanel.people.set({
			secondaryColor_admin_settings_changed_value:
				changedSettings.simplePaySettings.secondaryColor
		})
	}
	if (changedSettings.simplePaySettings.bodyFont) {
		mixpanel.people.set({
			bodyFont_admin_settings_changed_value:
				changedSettings.simplePaySettings.bodyFont
		})
	}
	if (changedSettings.simplePaySettings.headlineFont) {
		mixpanel.people.set({
			headlineFont_admin_settings_changed_value:
				changedSettings.simplePaySettings.headlineFont
		})
	}
	if (changedSettings.simplePaySettings.headerText) {
		mixpanel.people.set({
			headlineText_admin_settings_changed_value:
				changedSettings.simplePaySettings.headerText
		})
	}
	if (changedSettings.simplePaySettings.bannerType) {
		mixpanel.people.set({
			bannerType_admin_settings_changed_value:
				changedSettings.simplePaySettings.bannerType
		})
	}

	if (changedSettings.simplePaySettings.successOverrideUrl !== undefined) {
		mixpanel.people.set({
			successOverrideUrl_admin_settings_changed_value:
				changedSettings.simplePaySettings.successOverrideUrl
		})
	}
	if (changedSettings.simplePaySettings.backToHomeOverrideUrl !== undefined) {
		mixpanel.people.set({
			backToHomeOverrideUrl_admin_settings_changed_value:
				changedSettings.simplePaySettings.backToHomeOverrideUrl
		})
	}
	if (changedSettings.simplePaySettings.webHookUrl !== undefined) {
		mixpanel.people.set({
			webHookUrl_admin_settings_changed_value:
				changedSettings.simplePaySettings.webHookUrl
		})
	}
	// card fields
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.companyIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			card_companyIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.companyIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.companyIsRequired !== 'undefined'
	) {
		mixpanel.people.set({
			card_companyIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.companyIsRequired
		})
	}
	if (changedSettings.simplePaySettings.cardFields.companyLabelText) {
		mixpanel.people.set({
			card_companyLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.companyLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.addressIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			card_addressIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.addressIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.addressIsRequired !== 'undefined'
	) {
		mixpanel.people.set({
			card_addressIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.addressIsRequired
		})
	}
	if (changedSettings.simplePaySettings.cardFields.additionalInfoHeaderText) {
		mixpanel.people.set({
			card_additionalInfoHeaderText_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields
					.additionalInfoHeaderText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields.emailIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			card_emailIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.emailIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields.emailIsRequired !==
		'undefined'
	) {
		mixpanel.people.set({
			card_emailIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.emailIsRequired
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields.phoneIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			card_phoneIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.phoneIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields.phoneIsRequired !==
		'undefined'
	) {
		mixpanel.people.set({
			card_phoneIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.phoneIsRequired
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.orderIdIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			card_orderIdIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.orderIdIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.orderIdIsRequired !== 'undefined'
	) {
		mixpanel.people.set({
			card_orderIdIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.orderIdIsRequired
		})
	}
	if (changedSettings.simplePaySettings.cardFields.orderIdLabelText) {
		mixpanel.people.set({
			card_orderIdLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.orderIdLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.poNumberIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			card_poNumberIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.poNumberIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields
			.poNumberIsRequired !== 'undefined'
	) {
		mixpanel.people.set({
			card_poNumberIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.poNumberIsRequired
		})
	}
	if (changedSettings.simplePaySettings.cardFields.poNumberLabelText) {
		mixpanel.people.set({
			card_poNumberLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.poNumberLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.cardFields.noteIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			card_noteIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.cardFields.noteIsIncluded
		})
	}
	// // ach fields
	if (
		typeof changedSettings.simplePaySettings.achFields.companyIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_companyIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.companyIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields.companyIsRequired !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_companyIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.companyIsRequired
		})
	}
	if (changedSettings.simplePaySettings.achFields.companyLabelText) {
		mixpanel.people.set({
			ach_companyLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.companyLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields.noteIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_noteIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.noteIsIncluded
		})
	}
	if (changedSettings.simplePaySettings.achFields.additionalInfoHeaderText) {
		mixpanel.people.set({
			ach_additionalInfoHeaderText_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields
					.additionalInfoHeaderText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields.orderIdIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_orderIdIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.orderIdIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields.orderIdIsRequired !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_orderIdIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.orderIdIsRequired
		})
	}
	if (changedSettings.simplePaySettings.achFields.orderIdLabelText) {
		mixpanel.people.set({
			ach_orderIdLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.orderIdLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields
			.poNumberIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			ach_poNumberIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.poNumberIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields
			.poNumberIsRequired !== 'undefined'
	) {
		mixpanel.people.set({
			ach_poNumberIsRequired_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.poNumberIsRequired
		})
	}
	if (changedSettings.simplePaySettings.achFields.poNumberLabelText) {
		mixpanel.people.set({
			ach_poNumberLabelText_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.poNumberLabelText
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields
			.driversLicenseIsIncluded !== 'undefined'
	) {
		mixpanel.people.set({
			ach_driversLicenseIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields
					.driversLicenseIsIncluded
		})
	}
	if (
		typeof changedSettings.simplePaySettings.achFields.dobSSNIsIncluded !==
		'undefined'
	) {
		mixpanel.people.set({
			ach_dobSSNIsIncluded_admin_settings_changed_value:
				changedSettings.simplePaySettings.achFields.dobSSNIsIncluded
		})
	}
}

/***** Group Properties: Simplepay Page *****/

// sets the simplepay page id as the group id when the simplepay page loads
export function setSimplePayGroupId(simplePayId: string) {
	mixpanel.set_group('simple_pay_id', simplePayId)
}

// sets the simplepay settings on the on the group property
export function setSimplePaySettings(simplePayId: string, iq: any, fp: any) {
	// simplepay settings
	mixpanel.get_group('simple_pay_id', simplePayId).set({
		$name: iq.merchantName,
		'SimplePay Settings -> Merchant Name': iq.merchantName,
		'SimplePay Settings -> Card Processor Id': iq.cardProcessorId,
		'SimplePay Settings -> Ach Processor Id': iq.achProcessorId,
		'SimplePay Settings -> Active Account': iq.isActive,
		'SimplePay Settings -> Accepted Cards': iq.acceptedCards,
		'SimplePay Settings -> Ach -> Additional Info Header Text':
			iq.achFields.additionalInfoHeaderText,
		'SimplePay Settings -> Ach -> Company Included':
			iq.achFields.companyIsIncluded,
		'SimplePay Settings -> Ach -> Company Required':
			iq.achFields.companyIsRequired,
		'SimplePay Settings -> Ach -> Company Label Text':
			iq.achFields.companyLabelText,
		'SimplePay Settings -> Ach -> DobSSN Is Included':
			iq.achFields.dobSSNIsIncluded,
		'SimplePay Settings -> Ach -> Drivers License Included':
			iq.achFields.driversLicenseIsIncluded,
		'SimplePay Settings -> Ach -> Note Included':
			iq.achFields.noteIsIncluded,
		'SimplePay Settings -> Ach -> Order Id Included':
			iq.achFields.orderIdIsIncluded,
		'SimplePay Settings -> Ach -> Order Id Required':
			iq.achFields.orderIdIsRequired,
		'SimplePay Settings -> Ach -> Order Id Label Text':
			iq.achFields.orderIdLabelText,
		'SimplePay Settings -> Ach -> PoNumber Included':
			iq.achFields.poNumberIsIncluded,
		'SimplePay Settings -> Ach -> PoNumber Required':
			iq.achFields.poNumberIsRequired,
		'SimplePay Settings -> Ach -> PoNumber Label Text':
			iq.achFields.poNumberLabelText,
		'SimplePay Settings -> Ach Processing': iq.achProcessing,
		'SimplePay Settings -> Back To Home OverrideUrl':
			iq.backToHomeOverrideUrl,
		'SimplePay Settings -> Banner Message': iq.bannerMessage,
		'SimplePay Settings -> Banner Type': iq.bannerType,
		'SimplePay Settings -> Body Font': iq.bodyFont,
		'SimplePay Settings -> Card -> Additional Info Header Text':
			iq.cardFields.additionalInfoHeaderText,
		'SimplePay Settings -> Card -> Address Included':
			iq.cardFields.addressIsIncluded,
		'SimplePay Settings -> Card -> Address Required':
			iq.cardFields.addressIsRequired,
		'SimplePay Settings -> Card -> Company Included':
			iq.cardFields.companyIsIncluded,
		'SimplePay Settings -> Card -> Company Required':
			iq.cardFields.companyIsRequired,
		'SimplePay Settings -> Card -> Company Label Text':
			iq.cardFields.companyLabelText,
		'SimplePay Settings -> Card -> Email Included':
			iq.cardFields.emailIsIncluded,
		'SimplePay Settings -> Card -> Email Required':
			iq.cardFields.emailIsRequired,
		'SimplePay Settings -> Card -> Note Included':
			iq.cardFields.noteIsIncluded,
		'SimplePay Settings -> Card -> Order Id Included':
			iq.cardFields.orderIdIsIncluded,
		'SimplePay Settings -> Card -> Order Id Required':
			iq.cardFields.orderIdIsRequired,
		'SimplePay Settings -> Card -> Order Id LabelText':
			iq.cardFields.orderIdLabelText,
		'SimplePay Settings -> Card -> Phone Included':
			iq.cardFields.phoneIsIncluded,
		'SimplePay Settings -> Card -> Phone Required':
			iq.cardFields.phoneIsRequired,
		'SimplePay Settings -> Card -> PoNumber Included':
			iq.cardFields.poNumberIsIncluded,
		'SimplePay Settings -> Card -> PoNumber Required':
			iq.cardFields.poNumberIsRequired,
		'SimplePay Settings -> Card -> PoNumber Label Text':
			iq.cardFields.poNumberLabelText,
		'SimplePay Settings -> Decline Message': iq.declineMessage,
		'SimplePay Settings -> Enlarge Logo': iq.enlargeLogo,
		'SimplePay Settings -> Has Ach Form': iq.hasAchForm,
		'SimplePay Settings -> Has Banner': iq.hasBanner,
		'SimplePay Settings -> Has Card Form': iq.hasCardForm,
		'SimplePay Settings -> Header Text': iq.headerText,
		'SimplePay Settings -> Headline Font': iq.headlineFont,
		'SimplePay Settings -> Primary Color': iq.primaryColor,
		'SimplePay Settings -> Secondary Color': iq.secondaryColor,
		'SimplePay Settings -> Success Message': iq.successMessage,
		'SimplePay Settings -> Success Override Url': iq.successOverrideUrl,
		'SimplePay Settings -> Web Hook Url': iq.webHookUrl
	})
	// Fp merchant settings
	mixpanel.get_group('simple_pay_id', simplePayId).set({
		'FP Merchant Settings -> Custom Fields': [fp.custom_fields],
		// Settings -> Merchant -> Default Values -> Card Sync
		'FP Merchant Settings -> Merchant -> Default Values -> Card Sync -> Day of Month':
			fp.merchant.default_values.cardsync.day_of_the_month,
		'FP Merchant Settings -> Merchant -> Default Values -> Card Sync -> Submission Frequency':
			fp.merchant.default_values.cardsync.submission_frequency,

		// Settings -> Merchant -> Default Values -> Invoices
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Allow Partial Payment':
			fp.merchant.default_values.invoices.allow_partial_payment,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Enable Advanced Fields':
			fp.merchant.default_values.invoices.enable_advanced_fields,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Enable Shipping':
			fp.merchant.default_values.invoices.enable_shipping,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Enable Tax':
			fp.merchant.default_values.invoices.enable_tax,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Message':
			fp.merchant.default_values.invoices.message,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Require Shipping Details':
			fp.merchant.default_values.invoices.require_shipping_details,
		'FP Merchant Settings -> Merchant -> Default Values -> Invoices -> Transaction Type':
			fp.merchant.default_values.invoices.transaction_type,

		//Settings -> Merchant -> Default Values -> Line Items
		'FP Merchant Settings -> Merchant -> Default Values -> Line Items -> Commodity Code':
			fp.merchant.default_values.line_items.commodity_code,
		'FP Merchant Settings -> Merchant -> Default Values -> Line Items -> Local Tax Rate':
			fp.merchant.default_values.line_items.local_tax_rate,
		'FP Merchant Settings -> Merchant -> Default Values -> Line Items -> National Tax Rate':
			fp.merchant.default_values.line_items.national_tax_rate,
		'FP Merchant Settings -> Merchant -> Default Values -> Line Items -> Taxable':
			fp.merchant.default_values.line_items.taxable,
		'FP Merchant Settings -> Merchant -> Default Values -> Line Items -> Unit of Measure':
			fp.merchant.default_values.line_items.unit_of_measure,

		// Settings -> Merchant -> Default Values -> Merchant
		'FP Merchant Settings -> Merchant -> Default Values -> Merchant -> Currency':
			fp.merchant.default_values.merchant.currency,
		'FP Merchant Settings -> Merchant -> Default Values -> Merchant -> Ship From Postal Code':
			fp.merchant.default_values.merchant.ship_from_postal_code,
		'FP Merchant Settings -> Merchant -> Default Values -> Merchant -> Summary Commodity Code':
			fp.merchant.default_values.merchant.summary_commodity_code,
		'FP Merchant Settings -> Merchant -> Default Values -> Merchant -> Tax Rate':
			fp.merchant.default_values.merchant.tax_rate,

		// Settings -> Merchant -> Default Values -> Reoccuring
		'FP Merchant Settings -> Merchant -> Default Values -> Recurring -> Email Receipt':
			fp.merchant.default_values.recurring.email_receipt,

		// Settings -> Merchant -> Default Values -> Virtual Terminal
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Add Shipping to Total':
			fp.merchant.default_values.virtual_terminal.add_shipping_to_total,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Add Tax to Total':
			fp.merchant.default_values.virtual_terminal.add_tax_to_total,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Bypass Rule Engine':
			fp.merchant.default_values.virtual_terminal.bypass_rule_engine,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Calculate Total from Line Items':
			fp.merchant.default_values.virtual_terminal
				.calculate_total_from_line_items,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Email_receipt':
			fp.merchant.default_values.virtual_terminal.email_receipt,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Enable Advanced Fields':
			fp.merchant.default_values.virtual_terminal.enable_advanced_fields,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Print Receipt':
			fp.merchant.default_values.virtual_terminal.print_receipt,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Signature Required':
			fp.merchant.default_values.virtual_terminal.signature_required,
		'FP Merchant Settings -> Merchant -> Default Values -> Virtual Terminal -> Vault Record':
			fp.merchant.default_values.virtual_terminal.vault_record,

		// Settings -> Merchant -> Flags
		'FP Merchant Settings -> Merchant -> Flags -> Cash Discount Enabled':
			fp.merchant.flags.cash_discount_enabled,
		'FP Merchant Settings -> Merchant -> Flags -> Generic Cash Discount':
			fp.merchant.flags.generic_cash_discount,
		'FP Merchant Settings -> Merchant -> Flags -> It Fallback':
			fp.merchant.flags.it_fallback,
		'FP Merchant Settings -> Merchant -> Flags -> Per Processor Billing':
			fp.merchant.flags.per_processor_billing,
		'FP Merchant Settings -> Merchant -> Flags -> Skip Billing':
			fp.merchant.flags.skip_billing,
		'FP Merchant Settings -> Merchant -> Flags -> Split Payment Adjustment':
			fp.merchant.flags.split_payment_adjustment,
		'FP Merchant Settings -> Merchant -> Flags -> Surcharge Enabled':
			fp.merchant.flags.surcharge_enabled,
		'FP Merchant Settings -> Merchant -> Flags -> Surcharge Fallback':
			fp.merchant.flags.surcharge_fallback,

		// Settings -> Merchant -> Requireds -> Cart
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Allow_partial_payment':
			fp.merchant.requireds.cart.allow_partial_payment,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Amount':
			fp.merchant.requireds.cart.amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Address Line 1':
			fp.merchant.requireds.cart.billing_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Address Line 2':
			fp.merchant.requireds.cart.billing_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing City':
			fp.merchant.requireds.cart.billing_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Company':
			fp.merchant.requireds.cart.billing_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Country':
			fp.merchant.requireds.cart.billing_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Email':
			fp.merchant.requireds.cart.billing_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Fax':
			fp.merchant.requireds.cart.billing_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing First_name':
			fp.merchant.requireds.cart.billing_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Last_name':
			fp.merchant.requireds.cart.billing_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Phone':
			fp.merchant.requireds.cart.billing_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing Postal_code':
			fp.merchant.requireds.cart.billing_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Billing State':
			fp.merchant.requireds.cart.billing_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Create Vault Record':
			fp.merchant.requireds.cart.create_vault_record,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Currency':
			fp.merchant.requireds.cart.currency,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Date of Birth':
			fp.merchant.requireds.cart.date_of_birth,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Default':
			fp.merchant.requireds.cart.default,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Description':
			fp.merchant.requireds.cart.description,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Discount Amount':
			fp.merchant.requireds.cart.discount_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Drivers License':
			fp.merchant.requireds.cart.drivers_license,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Drivers License State':
			fp.merchant.requireds.cart.drivers_license_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Duty Amount':
			fp.merchant.requireds.cart.duty_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Email Address':
			fp.merchant.requireds.cart.email_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Email Receipt':
			fp.merchant.requireds.cart.email_receipt,
		Guaranty: fp.merchant.requireds.cart.guaranty,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Ip Address':
			fp.merchant.requireds.cart.ip_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Order Id':
			fp.merchant.requireds.cart.order_id,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Adjustment':
			fp.merchant.requireds.cart.payment_adjustment,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Method Ach':
			fp.merchant.requireds.cart.payment_method_ach,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Method Card':
			fp.merchant.requireds.cart.payment_method_card,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Method Card Cvc':
			fp.merchant.requireds.cart.payment_method_card_cvc,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Method Customer Token':
			fp.merchant.requireds.cart.payment_method_customer_token,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Payment Method Terminal':
			fp.merchant.requireds.cart.payment_method_terminal,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Po Number':
			fp.merchant.requireds.cart.po_number,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Ship from Postal Code':
			fp.merchant.requireds.cart.ship_from_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Address Line 1':
			fp.merchant.requireds.cart.shipping_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Address Line 2':
			fp.merchant.requireds.cart.shipping_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Amount':
			fp.merchant.requireds.cart.shipping_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping City':
			fp.merchant.requireds.cart.shipping_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Company':
			fp.merchant.requireds.cart.shipping_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Country':
			fp.merchant.requireds.cart.shipping_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Email':
			fp.merchant.requireds.cart.shipping_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Fax':
			fp.merchant.requireds.cart.shipping_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping First_name':
			fp.merchant.requireds.cart.shipping_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Last_name':
			fp.merchant.requireds.cart.shipping_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Phone':
			fp.merchant.requireds.cart.shipping_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping Postal_code':
			fp.merchant.requireds.cart.shipping_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Shipping State':
			fp.merchant.requireds.cart.shipping_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Summary Commodity Code':
			fp.merchant.requireds.cart.summary_commodity_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Tax Amount':
			fp.merchant.requireds.cart.tax_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Tax Exempt':
			fp.merchant.requireds.cart.tax_exempt,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Type':
			fp.merchant.requireds.cart.type,
		'FP Merchant Settings -> Merchant -> Requireds -> Cart -> Vendor Id':
			fp.merchant.requireds.cart.vendor_id,

		// Settings -> Merchant -> Requireds -> Default
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Allow_partial_payment':
			fp.merchant.requireds.default.allow_partial_payment,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Amount':
			fp.merchant.requireds.default.amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Address Line 1':
			fp.merchant.requireds.default.billing_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Address Line 2':
			fp.merchant.requireds.default.billing_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing City':
			fp.merchant.requireds.default.billing_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Company':
			fp.merchant.requireds.default.billing_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Country':
			fp.merchant.requireds.default.billing_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Email':
			fp.merchant.requireds.default.billing_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Fax':
			fp.merchant.requireds.default.billing_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing First_name':
			fp.merchant.requireds.default.billing_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Last_name':
			fp.merchant.requireds.default.billing_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Phone':
			fp.merchant.requireds.default.billing_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing Postal_code':
			fp.merchant.requireds.default.billing_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Billing State':
			fp.merchant.requireds.default.billing_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Create Vault Record':
			fp.merchant.requireds.default.create_vault_record,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Currency':
			fp.merchant.requireds.default.currency,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Date of Birth':
			fp.merchant.requireds.default.date_of_birth,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Default':
			fp.merchant.requireds.default.default,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Description':
			fp.merchant.requireds.default.description,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Discount Amount':
			fp.merchant.requireds.default.discount_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Drivers License State':
			fp.merchant.requireds.default.drivers_license_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Duty Amount':
			fp.merchant.requireds.default.duty_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Email Address':
			fp.merchant.requireds.default.email_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Email Receipt':
			fp.merchant.requireds.default.email_receipt,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Guaranty':
			fp.merchant.requireds.default.guaranty,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Ip Address':
			fp.merchant.requireds.default.ip_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> National Tax Amount':
			fp.merchant.requireds.default.national_tax_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Order Id':
			fp.merchant.requireds.default.order_id,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Adjustment':
			fp.merchant.requireds.default.payment_adjustment,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Method Ach':
			fp.merchant.requireds.default.payment_method_ach,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Method Card':
			fp.merchant.requireds.default.payment_method_card,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Method Card Cvc':
			fp.merchant.requireds.default.payment_method_card_cvc,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Method Customer Token':
			fp.merchant.requireds.default.payment_method_customer_token,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Payment Method Terminal':
			fp.merchant.requireds.default.payment_method_terminal,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Po Number':
			fp.merchant.requireds.default.po_number,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Processor Id':
			fp.merchant.requireds.default.processor_id,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Ship from Postal Code':
			fp.merchant.requireds.default.ship_from_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Address Line 1':
			fp.merchant.requireds.default.shipping_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Address Line 2':
			fp.merchant.requireds.default.shipping_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Amount':
			fp.merchant.requireds.default.shipping_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping City':
			fp.merchant.requireds.default.shipping_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Company':
			fp.merchant.requireds.default.shipping_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Country':
			fp.merchant.requireds.default.shipping_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Email':
			fp.merchant.requireds.default.shipping_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Fax':
			fp.merchant.requireds.default.shipping_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping First_name':
			fp.merchant.requireds.default.shipping_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Last_name':
			fp.merchant.requireds.default.shipping_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Phone':
			fp.merchant.requireds.default.shipping_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping Postal_code':
			fp.merchant.requireds.default.shipping_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Shipping State':
			fp.merchant.requireds.default.shipping_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Summary Commodity Code':
			fp.merchant.requireds.default.summary_commodity_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Tax Amount':
			fp.merchant.requireds.default.tax_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Tax Exempt':
			fp.merchant.requireds.default.tax_exempt,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Type':
			fp.merchant.requireds.default.type,
		'FP Merchant Settings -> Merchant -> Requireds -> Default -> Vendor Id':
			fp.merchant.requireds.default.vendor_id,

		// Settings -> Merchant -> Requireds -> Invoice
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Allow_partial_payment':
			fp.merchant.requireds.invoice.allow_partial_payment,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Amount':
			fp.merchant.requireds.invoice.amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Address Line 1':
			fp.merchant.requireds.invoice.billing_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Address Line 2':
			fp.merchant.requireds.invoice.billing_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing City':
			fp.merchant.requireds.invoice.billing_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Company':
			fp.merchant.requireds.invoice.billing_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Country':
			fp.merchant.requireds.invoice.billing_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Email':
			fp.merchant.requireds.invoice.billing_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Fax':
			fp.merchant.requireds.invoice.billing_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing First_name':
			fp.merchant.requireds.invoice.billing_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Last_name':
			fp.merchant.requireds.invoice.billing_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Phone':
			fp.merchant.requireds.invoice.billing_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing Postal_code':
			fp.merchant.requireds.invoice.billing_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Billing State':
			fp.merchant.requireds.invoice.billing_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Create Vault Record':
			fp.merchant.requireds.invoice.create_vault_record,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Currency':
			fp.merchant.requireds.invoice.currency,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Date of Birth':
			fp.merchant.requireds.invoice.date_of_birth,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Default':
			fp.merchant.requireds.invoice.invoice,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Description':
			fp.merchant.requireds.invoice.description,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Discount Amount':
			fp.merchant.requireds.invoice.discount_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Drivers License State':
			fp.merchant.requireds.invoice.drivers_license_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Duty Amount':
			fp.merchant.requireds.invoice.duty_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Email Address':
			fp.merchant.requireds.invoice.email_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Email Receipt':
			fp.merchant.requireds.invoice.email_receipt,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Guaranty':
			fp.merchant.requireds.invoice.guaranty,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Ip Address':
			fp.merchant.requireds.invoice.ip_address,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> National Tax Amount':
			fp.merchant.requireds.invoice.national_tax_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Order Id':
			fp.merchant.requireds.invoice.order_id,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Adjustment':
			fp.merchant.requireds.invoice.payment_adjustment,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Method Ach':
			fp.merchant.requireds.invoice.payment_method_ach,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Method Card':
			fp.merchant.requireds.invoice.payment_method_card,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Method Card Cvc':
			fp.merchant.requireds.invoice.payment_method_card_cvc,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Method Customer Token':
			fp.merchant.requireds.invoice.payment_method_customer_token,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Payment Method Terminal':
			fp.merchant.requireds.invoice.payment_method_terminal,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Po Number':
			fp.merchant.requireds.invoice.po_number,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Processor Id':
			fp.merchant.requireds.invoice.processor_id,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Ship from Postal Code':
			fp.merchant.requireds.invoice.ship_from_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Address Line 1':
			fp.merchant.requireds.invoice.shipping_address_line_1,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Address Line 2':
			fp.merchant.requireds.invoice.shipping_address_line_2,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Amount':
			fp.merchant.requireds.invoice.shipping_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping City':
			fp.merchant.requireds.invoice.shipping_city,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Company':
			fp.merchant.requireds.invoice.shipping_company,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Country':
			fp.merchant.requireds.invoice.shipping_country,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Email':
			fp.merchant.requireds.invoice.shipping_email,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Fax':
			fp.merchant.requireds.invoice.shipping_fax,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping First_name':
			fp.merchant.requireds.invoice.shipping_first_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Last_name':
			fp.merchant.requireds.invoice.shipping_last_name,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Phone':
			fp.merchant.requireds.invoice.shipping_phone,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping Postal_code':
			fp.merchant.requireds.invoice.shipping_postal_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Shipping State':
			fp.merchant.requireds.invoice.shipping_state,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Summary Commodity Code':
			fp.merchant.requireds.invoice.summary_commodity_code,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Tax Amount':
			fp.merchant.requireds.invoice.tax_amount,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Tax Exempt':
			fp.merchant.requireds.invoice.tax_exempt,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Type':
			fp.merchant.requireds.invoice.type,
		'FP Merchant Settings -> Merchant -> Requireds -> Invoice -> Vendor Id':
			fp.merchant.requireds.invoice.vendor_id,

		'FP Merchant Settings -> Processor Settings': fp.processor_settings
	})
}

// sets the simplepay card processor settings on the group property
export function setSimplePayCardProcessor(group: GroupProperty) {
	mixpanel.get_group('simple_pay_id', group.simplePayId).set({
		'SimplePay Settings -> Card Processor': group.processor
	})
}

// sets the simplepay ach processor settings on the group property
export function setSimplePayAchProcessor(group: GroupProperty) {
	mixpanel.get_group('simple_pay_id', group.simplePayId).set({
		'SimplePay Settings -> Ach Processor': group.processor
	})
}

// sets the redirect property on the group property if the simplepay page currently has redirect on
export function setSimplePayRedirect(group: GroupProperty) {
	mixpanel.get_group('simple_pay_id', group.simplePayId).set({
		'Has Redirect Link': group.settings.successOverrideUrl
	})
}

// sets group property if the page has been deactivate and tracks event with it
export function setDeactivatedPage(group: GroupProperty) {
	mixpanel.track_with_groups(
		'SimplePay Page has been deactivated',
		{
			'Dectivated in Admin': group.deactivated,
			'Deactivated in Admin By': group.deactivatedBy,
			'Activated in Admin': group.deactivated
		},
		{ simple_pay_id: [group.simplePayId] }
	)
	mixpanel.get_group('simple_pay_id', group.simplePayId).set({
		$name: group.merchantName,
		'Dectivated in Admin': group.deactivated,
		'Deactivated in Admin By': group.deactivatedBy,
		'Activated in Admin': group.deactivated
	})
}

// sets group property if the page has been activated and tracks event with it
export function setActivatedPage(group: GroupProperty) {
	mixpanel.track_with_groups(
		'SimplePay Page has been activated',
		{},
		{ simple_pay_id: [group.simplePayId] }
	)
	mixpanel.get_group('simple_pay_id', group.simplePayId).set({
		$name: group.merchantName,
		'Activated in Admin': true,
		'Activated in Admin By': group.activatedBy,
		'Deactivated in Admin': false
	})
}

/***** General Transaction Properties: Events Properties *****/

// called when the user fills out the form incorrectly, and the payment has been prevented from processing
export function transactionPreventedFromProcessing(payload: any) {
	mixpanel.track('Transaction Prevented From Processing', {
		'Transaction Prevented From Procesing -> Allow Partial Payments':
			payload.allow_partial_payment,
		'Transaction Prevented From Procesing -> Amount':
			payload.requestBody.amount,
		// Billing Address
		'Transaction Prevented From Procesing -> Billing Address -> Address Line 1':
			payload.requestBody.billing_address.address_line_1,
		'Transaction Prevented From Procesing -> Billing Address -> Address Line 2':
			payload.requestBody.billing_address.address_line_2,
		'Transaction Prevented From Procesing -> Billing Address -> City':
			payload.requestBody.billing_address.city,
		'Transaction Prevented From Procesing -> Billing Address -> Company':
			payload.requestBody.billing_address.company,
		'Transaction Prevented From Procesing -> Billing Address -> Country':
			payload.requestBody.billing_address.country,
		'Transaction Prevented From Procesing -> Billing  Address -> Email':
			payload.requestBody.billing_address.email,
		'Transaction Prevented From Procesing -> Billing Address -> Fax':
			payload.requestBody.billing_address.fax,
		'Transaction Prevented From Procesing -> Billing Address -> First Name':
			payload.requestBody.billing_address.first_name,
		'Transaction Prevented From Procesing -> Billing Address -> Last Name':
			payload.requestBody.billing_address.last_name,
		'Transaction Prevented From Procesing -> Billing Address -> Phone':
			payload.requestBody.billing_address.phone,
		'Transaction Prevented From Procesing -> Billing Address -> State':
			payload.requestBody.billing_address.state,
		'Transaction Prevented From Procesing -> Billing Address -> Postal Code':
			payload.requestBody.billing_address.postal_code,
		'Transaction Prevented From Procesing -> Vault Field':
			payload.requestBody.create_vault_record,
		'Transaction Prevented From Procesing  -> Currency':
			payload.requestBody.currency,
		'Transaction Prevented From Procesing  -> Custom Fields':
			payload.requestBody.custom_fields,
		'Transaction Prevented From Procesing  -> Default':
			payload.requestBody.default,
		'Transaction Prevented From Procesing  -> Note':
			payload.requestBody.description,
		'Transaction Prevented From Procesing  -> Discount Amount':
			payload.requestBody.discount_amount,
		'Transaction Prevented From Procesing -> Email Address':
			payload.requestBody.email_address,
		'Transaction Prevented From Procesing -> Email Receipt':
			payload.requestBody.email_receipt,
		'Transaction Prevented From Procesing -> Group Name':
			payload.requestBody.group_name,
		'Transaction Prevented From Procesing -> National Tax Amount':
			payload.requestBody.national_tax_amount,
		'Transaction Prevented From Procesing -> Order Id':
			payload.requestBody.order_id,
		'Transaction Prevented From Procesing -> Payment Method':
			payload.requestBody.payment_method.token,
		'Transaction Prevented From Procesing -> Po Number':
			payload.requestBody.po_number,
		'Transaction Prevented From Procesing -> Processor Id':
			payload.requestBody.processor_id,
		'Transaction Prevented From Procesing ->   Ship From Postal Code':
			payload.requestBody.ship_from_postal_code,
		// Transaction Prevented From Procesing -> Shipping Address
		'Transaction Prevented From Procesing -> Shipping Address -> First Name':
			payload.requestBody.shipping_address.first_name,
		'Transaction Prevented From Procesing -> Shipping Address -> Last Name':
			payload.requestBody.shipping_address.last_name,
		'Transaction Prevented From Procesing -> Shipping Address -> Company':
			payload.requestBody.shipping_address.company,
		'Transaction Prevented From Procesing -> Shipping Address -> Address Line 1':
			payload.requestBody.shipping_address.address_line_1,
		'Transaction Prevented From Procesing -> Shipping Address -> Address Line 2':
			payload.requestBody.shipping_address.address_line_2,
		'Transaction Prevented From Procesing -> Shipping Address -> City':
			payload.requestBody.shipping_address.city,
		'Transaction Prevented From Procesing -> Shipping Address -> State':
			payload.requestBody.shipping_address.state,
		'Transaction Prevented From Procesing -> Shipping Address -> Postal Code':
			payload.requestBody.shipping_address.postal_code,
		'Transaction Prevented From Procesing -> Shipping Address -> Country':
			payload.requestBody.shipping_address.country,
		'Transaction Prevented From Procesing -> Shipping Address -> Email':
			payload.requestBody.shipping_address.email,
		'Transaction Prevented From Procesing -> Shipping Address -> Phone':
			payload.requestBody.shipping_address.phone,
		'Transaction Prevented From Procesing -> Shipping Address -> Fax':
			payload.requestBody.shipping_address.fax,
		'Transaction Prevented From Procesing ->   Shipping Amount':
			payload.requestBody.shipping_amount,
		'Transaction Prevented From Procesing ->   Summary Commodity Code':
			payload.requestBody.summary_commodity_code,
		'Transaction Prevented From Procesing ->   Tax Except':
			payload.requestBody.tax_exempt,
		'Transaction Prevented From Procesing ->   Tax Amount':
			payload.requestBody.tax_amount,
		'Transaction Prevented From Procesing -> Type': payload.requestBody.type
	})
}

/***** Card Form: Events Properties *****/
// called on pre submit when user selects a stateless country
export function cardIsStateless(country: string) {
	mixpanel.track('Card Stateless Country on PreSubmit', {
		'Card -> Stateless Country': country
	})
}
// called on pre submit before validation happens to catch un processed transactions
export function cardPresubmitValues(payload: any) {
	mixpanel.track('Card PreSubmit', {
		'Card Form Presubmit Data': payload
	})
}
// called when user submits a successful transactions and adds transaction details to an event that is associated with the simplepay group
export function cardFormTrackTransactionSubmissionSuccess(
	transactionDetails: any
) {
	mixpanel.track('Submitted Card Transaction Success', {
		'Card -> Billing ->  Type': transactionDetails.type,
		'Card -> Billing ->  Amount': transactionDetails.amount,
		'Card -> Billing ->  Currency': transactionDetails.currency,
		'Card -> Billing ->  Email Receipt': transactionDetails.email_receipt,
		'Card -> Billing ->  Group Name': transactionDetails.group_name,
		'Card -> Billing ->  Custom Fields': transactionDetails.custom_fields,
		'Card -> Billing ->  Processor Id': transactionDetails.processor_id,
		'Card -> Billing ->  Surcharge': transactionDetails.surcharge,
		'Card -> Billing ->  National Tax Amount':
			transactionDetails.national_tax_amount,
		'Card -> Billing ->  Duty Amount': transactionDetails.duty_amount,
		'Card -> Billing ->  Ship From Postal Code':
			transactionDetails.ship_from_postal_code,
		'Card -> Billing ->  Summary Commodity Code':
			transactionDetails.summary_commodity_code,
		'Card -> Billing ->  Email Address': transactionDetails.email_address,
		'Card -> Billing ->  Order Id': transactionDetails.order_id,
		'Card -> Billing ->  Po Number': transactionDetails.po_number,
		'Card -> Billing ->  Note': transactionDetails.description,
		'Card -> Billing ->  Default': transactionDetails.default,
		'Card -> Billing ->  Tax Except': transactionDetails.tax_exempt,
		'Card -> Billing ->  Allow Partial Payments':
			transactionDetails.allow_partial_payment,
		'Card -> Billing -> Vault Field':
			transactionDetails.create_vault_record,
		'Card -> Billing ->  Tax Amount': transactionDetails.tax_amount,
		'Card -> Billing ->  Shipping Amount':
			transactionDetails.shipping_amount,
		'Card -> Billing ->  Discount Amount':
			transactionDetails.discount_amount,
		'Card -> Billing ->  Payment Method':
			transactionDetails.payment_method.token,
		// Billing address
		'Card -> Billing Address -> First Name':
			transactionDetails.billing_address.first_name,
		'Card -> Billing Address -> Last Name':
			transactionDetails.billing_address.last_name,
		'Card -> Billing Address -> Country':
			transactionDetails.billing_address.country,
		'Card -> Billing Address -> Company':
			transactionDetails.billing_address.company,
		'Card -> Billing Address -> Address Line 1':
			transactionDetails.billing_address.address_line_1,
		'Card -> Billing Address -> Address Line 2':
			transactionDetails.billing_address.address_line_2,
		'Card -> Billing Address -> City':
			transactionDetails.billing_address.city,
		'Card -> Billing Address -> State':
			transactionDetails.billing_address.state,
		'Card -> Billing Address -> Postal Code':
			transactionDetails.billing_address.postal_code,
		'Card -> Billing Address -> Email':
			transactionDetails.billing_address.email,
		'Card -> Billing Address -> Phone':
			transactionDetails.billing_address.phone,
		'Card -> Billing Address -> Fax':
			transactionDetails.billing_address.fax,
		// Shipping Address
		'Card -> Shipping Address -> First Name':
			transactionDetails.shipping_address.first_name,
		'Card -> Shipping Address -> Last Name':
			transactionDetails.shipping_address.last_name,
		'Card -> Shipping Address -> Company':
			transactionDetails.shipping_address.company,
		'Card -> Shipping Address -> Address Line 1':
			transactionDetails.shipping_address.address_line_1,
		'Card -> Shipping Address -> Address Line 2':
			transactionDetails.shipping_address.address_line_2,
		'Card -> Shipping Address -> City':
			transactionDetails.shipping_address.city,
		'Card -> Shipping Address -> State':
			transactionDetails.shipping_address.state,
		'Card -> Shipping Address -> Postal Code':
			transactionDetails.shipping_address.postal_code,
		'Card -> Shipping Address -> Country':
			transactionDetails.shipping_address.country,
		'Card -> Shipping Address -> Email':
			transactionDetails.shipping_address.email,
		'Card -> Shipping Address -> Phone':
			transactionDetails.shipping_address.phone,
		'Card -> Shipping Address -> Fax':
			transactionDetails.shipping_address.fax,
		// response body response
		'Card -> Response Body -> Auth Code':
			transactionDetails.response_body.card.auth_code,
		'Card -> Response Body -> AVS Response Code':
			transactionDetails.response_body.card.avs_response_code,
		'Card -> Response Body -> Proceessor Response Code':
			transactionDetails.response_body.card.processor_response_code,
		'Card -> Response Body -> Proceessor Response Text':
			transactionDetails.response_body.card.processor_response_text,
		'Card -> Response Body -> Processor Specific':
			transactionDetails.response_body.card.processor_specific,
		'Card -> Response Body -> Processor Type':
			transactionDetails.response_body.card.processor_type,
		'Card -> Response Body -> Response Code':
			transactionDetails.response_body.card.response_code,
		// decline or success
		'Card -> Response Body -> Response':
			transactionDetails.response_body.card.response,
		'Card -> Transaction Id': transactionDetails.id,
		'Card -> Timestamp': new Date(Date.now()).toLocaleString(),
		'Card -> Transaction Amount': transactionDetails.amount_authorized,
		'Card -> Response Code': transactionDetails.response_code
	})
}

// called when user submits a decline/unknown transactions and adds transaction details to an event that is associated with the simplepay group
export function cardFormTrackTransactionSubmissionDecline(
	transactionDetails: any
) {
	mixpanel.track('Submitted Card Transaction Decline', {
		'Card -> Billing ->  Type': transactionDetails.type,
		'Card -> Billing ->  Amount': transactionDetails.amount,
		'Card -> Billing ->  Currency': transactionDetails.currency,
		'Card -> Billing ->  Email Receipt': transactionDetails.email_receipt,
		'Card -> Billing ->  Group Name': transactionDetails.group_name,
		'Card -> Billing ->  Custom Fields': transactionDetails.custom_fields,
		'Card -> Billing ->  Processor Id': transactionDetails.processor_id,
		'Card -> Billing ->  Surcharge': transactionDetails.surcharge,
		'Card -> Billing ->  National Tax Amount':
			transactionDetails.national_tax_amount,
		'Card -> Billing ->  Duty Amount': transactionDetails.duty_amount,
		'Card -> Billing ->  Ship From Postal Code':
			transactionDetails.ship_from_postal_code,
		'Card -> Billing ->  Summary Commodity Code':
			transactionDetails.summary_commodity_code,
		'Card -> Billing ->  Email Address': transactionDetails.email_address,
		'Card -> Billing ->  Order Id': transactionDetails.order_id,
		'Card -> Billing ->  Po Number': transactionDetails.po_number,
		'Card -> Billing ->  Note': transactionDetails.description,
		'Card -> Billing ->  Default': transactionDetails.default,
		'Card -> Billing ->  Tax Except': transactionDetails.tax_exempt,
		'Card -> Billing ->  Allow Partial Payments':
			transactionDetails.allow_partial_payment,
		'Card -> Billing -> Vault Field':
			transactionDetails.create_vault_record,
		'Card -> Billing ->  Tax Amount': transactionDetails.tax_amount,
		'Card -> Billing ->  Shipping Amount':
			transactionDetails.shipping_amount,
		'Card -> Billing ->  Discount Amount':
			transactionDetails.discount_amount,
		'Card -> Billing ->  Payment Method':
			transactionDetails.payment_method.token,
		// Billing address
		'Card -> Billing Address -> First Name':
			transactionDetails.billing_address.first_name,
		'Card -> Billing Address -> Last Name':
			transactionDetails.billing_address.last_name,
		'Card -> Billing Address -> Country':
			transactionDetails.billing_address.country,
		'Card -> Billing Address -> Company':
			transactionDetails.billing_address.company,
		'Card -> Billing Address -> Address Line 1':
			transactionDetails.billing_address.address_line_1,
		'Card -> Billing Address -> Address Line 2':
			transactionDetails.billing_address.address_line_2,
		'Card -> Billing Address -> City':
			transactionDetails.billing_address.city,
		'Card -> Billing Address -> State':
			transactionDetails.billing_address.state,
		'Card -> Billing Address -> Postal Code':
			transactionDetails.billing_address.postal_code,
		'Card -> Billing Address -> Email':
			transactionDetails.billing_address.email,
		'Card -> Billing Address -> Phone':
			transactionDetails.billing_address.phone,
		'Card -> Billing Address -> Fax':
			transactionDetails.billing_address.fax,
		// Shipping Address
		'Card -> Shipping Address -> First Name':
			transactionDetails.shipping_address.first_name,
		'Card -> Shipping Address -> Last Name':
			transactionDetails.shipping_address.last_name,
		'Card -> Shipping Address -> Company':
			transactionDetails.shipping_address.company,
		'Card -> Shipping Address -> Address Line 1':
			transactionDetails.shipping_address.address_line_1,
		'Card -> Shipping Address -> Address Line 2':
			transactionDetails.shipping_address.address_line_2,
		'Card -> Shipping Address -> City':
			transactionDetails.shipping_address.city,
		'Card -> Shipping Address -> State':
			transactionDetails.shipping_address.state,
		'Card -> Shipping Address -> Postal Code':
			transactionDetails.shipping_address.postal_code,
		'Card -> Shipping Address -> Country':
			transactionDetails.shipping_address.country,
		'Card -> Shipping Address -> Email':
			transactionDetails.shipping_address.email,
		'Card -> Shipping Address -> Phone':
			transactionDetails.shipping_address.phone,
		'Card -> Shipping Address -> Fax':
			transactionDetails.shipping_address.fax,
		// response body response
		'Card -> Response Body -> Auth Code':
			transactionDetails.response_body.card.auth_code,
		'Card -> Response Body -> AVS Response Code':
			transactionDetails.response_body.card.avs_response_code,
		'Card -> Response Body -> Proceessor Response Code':
			transactionDetails.response_body.card.processor_response_code,
		'Card -> Response Body -> Proceessor Response Text':
			transactionDetails.response_body.card.processor_response_text,
		'Card -> Response Body -> Processor Specific':
			transactionDetails.response_body.card.processor_specific,
		'Card -> Response Body -> Processor Type':
			transactionDetails.response_body.card.processor_type,
		'Card -> Response Body -> Response Code':
			transactionDetails.response_body.card.response_code,
		// decline or success
		'Card -> Response Body -> Response':
			transactionDetails.response_body.card.response,
		'Card -> Transaction Id': transactionDetails.id,
		'Card -> Timestamp': new Date(Date.now()).toLocaleString(),
		'Card -> Transaction Amount': transactionDetails.amount_authorized,
		'Card -> Response Code': transactionDetails.response_code
	})
}

// starts a timer when the user starts to fill out the form and clicks on the 'Full Name' field and compares it to when the submitTransaction
// event is called, giving us how long it takes to fill out the form
export function cardFormTrackTime() {
	mixpanel.time_event('Submitted Card Transaction')
}

// called when user views card form and tracks it to the group the simplepay group
export function cardFormPageViewTrack() {
	mixpanel.track('SimplePay Card Form Viewed')
}

/***** ACH Form: Events Properties *****/

// called on pre submit when user selects a stateless country
export function achIsStateless(country: string) {
	mixpanel.track('ACH Stateless Country on PreSubmit', {
		'ACH -> Stateless Country': country
	})
}
// called on pre submit before validation happens to catch un processed transactions
export function achPresubmitValues(payload: any) {
	mixpanel.track('ACH PreSubmit', {
		'ACH Form Presubmit Data': payload
	})
}
// called when user submits a successful transactions and adds transaction detials to an event that is associated with the simplepay group
export function achFormTrackTransactionSubmissionSuccess(
	transactionDetails: any
) {
	mixpanel.track('Submitted ACH Transaction Success', {
		'Ach -> Billing -> Type': transactionDetails.type,
		'Ach -> Billing -> Amount': transactionDetails.amount,
		'Ach -> Billing -> Currency': transactionDetails.currency,
		'Ach -> Billing -> Email Receipt': transactionDetails.email_receipt,
		'Ach -> Billing -> Group Name': transactionDetails.group_name,
		'Ach -> Billing -> Order Id': transactionDetails.order_id,
		'Ach -> Billing -> Po Numer': transactionDetails.po_number,
		'Ach -> Billing -> Note': transactionDetails.description,
		'Ach -> Billing -> Defualt': transactionDetails.default,
		'Ach -> Billing -> Tax Excempt': transactionDetails.tax_exempt,
		'Ach -> Billing -> Email Address': transactionDetails.email_address,
		'Ach -> Billing -> Allow Partial Payment':
			transactionDetails.allow_partial_payment,
		'Ach -> Billing -> Vault Field': transactionDetails.create_vault_record,
		'Ach -> Billing -> Tax Amount': transactionDetails.tax_amount,
		'Ach -> Billing -> Ship From Postal Code':
			transactionDetails.ship_from_postal_code,
		'Ach -> Billing -> Summary Commodity Code':
			transactionDetails.summary_commodity_code,
		'Ach -> Billing -> Processor Id': transactionDetails.processor_id,
		'Ach -> Billing -> Custom Fields': transactionDetails.custom_fields,
		'Ach -> Billing -> Payment Method':
			transactionDetails.payment_method.token,
		// Billing address
		'Ach -> Billing Address -> First Name':
			transactionDetails.billing_address.first_name,
		'Ach -> Billing Address -> Last Name':
			transactionDetails.billing_address.last_name,
		'Ach -> Billing Address -> Country':
			transactionDetails.billing_address.country,
		'Ach -> Billing Address -> Address Line 1':
			transactionDetails.billing_address.address_line_1,
		'Ach -> Billing Address -> Address Line 2':
			transactionDetails.billing_address.address_line_2,
		'Ach -> Billing Address -> City':
			transactionDetails.billing_address.city,
		'Ach -> Billing Address -> State':
			transactionDetails.billing_address.state,
		'Ach -> Billing Address -> Postal Code':
			transactionDetails.billing_address.postal_code,
		'Ach -> Billing Address -> Email':
			transactionDetails.billing_address.email,
		'Ach -> Billing Address -> Company':
			transactionDetails.billing_address.company,
		'Ach -> Billing Address -> Phone':
			transactionDetails.billing_address.phone,
		'Ach -> Billing Address -> Fax': transactionDetails.billing_address.fax,
		// Shipping Address
		'Ach -> Billing Shipping Address -> First Name':
			transactionDetails.shipping_address.first_name,
		'Ach -> Billing Shipping Address -> Last Name':
			transactionDetails.shipping_address.last_name,
		'Ach -> Billing Shipping Address -> Company':
			transactionDetails.shipping_address.company,
		'Ach -> Billing Shipping Address -> Address Line 1':
			transactionDetails.shipping_address.address_line_1,
		'Ach -> Billing Shipping Address -> Address Line 2':
			transactionDetails.shipping_address.address_line_2,
		'Ach -> Billing Shipping Address -> City':
			transactionDetails.shipping_address.city,
		'Ach -> Billing Shipping Address -> State':
			transactionDetails.shipping_address.state,
		'Ach -> Billing Shipping Address -> Postal Code':
			transactionDetails.shipping_address.postal_code,
		'Ach -> Billing Shipping Address -> Country':
			transactionDetails.shipping_address.country,
		'Ach -> Billing Shipping Address -> Email':
			transactionDetails.shipping_address.email,
		'Ach -> Billing Shipping Address -> Phone':
			transactionDetails.shipping_address.phone,
		'Ach -> Billing Shipping Address -> Fax':
			transactionDetails.shipping_address.fax,
		// Response Body
		'Ach -> Response Body -> Sec Code':
			transactionDetails.response_body.ach.sec_code,
		'Ach -> Response Body -> Processor Response Code':
			transactionDetails.response_body.ach.processor_response_code,
		'Ach -> Response Body -> Processor Response Text':
			transactionDetails.response_body.ach.processor_response_text,
		'Ach Response Body -> Account Type':
			transactionDetails.response_body.ach.account_type,
		'Ach -> Response Body -> Processor Type':
			transactionDetails.response_body.ach.type,
		// Response body -> approved or decline
		'Ach -> Response Body -> Response':
			transactionDetails.response_body.ach.response,
		// Root
		'Ach -> Status': transactionDetails.status,
		'Ach -> Type': transactionDetails.type,
		'Ach -> Transaction Source': transactionDetails.transaction_source,
		'Ach -> Account Username': transactionDetails.user_name,
		'Ach -> Response Body -> Auth Code':
			transactionDetails.response_body.ach.response.auth_code,
		'Ach -> Transaction Id': transactionDetails.id,
		'Ach -> Timestamp': new Date(Date.now()).toLocaleString(),
		'Ach -> Transaction Amount': transactionDetails.amount_authorized,
		'Ach -> Response Code': transactionDetails.response_code
	})
}

// called when user submits a decline/unknown transactions and adds transaction details to an event that is associated with the simplepay group
export function achFormTrackTransactionSubmissionDecline(
	transactionDetails: any
) {
	mixpanel.track('Submitted ACH Transaction Decline', {
		'Ach -> Billing -> Type': transactionDetails.type,
		'Ach -> Billing -> Amount': transactionDetails.amount,
		'Ach -> Billing -> Currency': transactionDetails.currency,
		'Ach -> Billing -> Email Receipt': transactionDetails.email_receipt,
		'Ach -> Billing -> Group Name': transactionDetails.group_name,
		'Ach -> Billing -> Order Id': transactionDetails.order_id,
		'Ach -> Billing -> Po Numer': transactionDetails.po_number,
		'Ach -> Billing -> Note': transactionDetails.description,
		'Ach -> Billing -> Defualt': transactionDetails.default,
		'Ach -> Billing -> Tax Excempt': transactionDetails.tax_exempt,
		'Ach -> Billing -> Email Address': transactionDetails.email_address,
		'Ach -> Billing -> Allow Partial Payment':
			transactionDetails.allow_partial_payment,
		'Ach -> Billing -> Vault Field': transactionDetails.create_vault_record,
		'Ach -> Billing -> Tax Amount': transactionDetails.tax_amount,
		'Ach -> Billing -> Ship From Postal Code':
			transactionDetails.ship_from_postal_code,
		'Ach -> Billing -> Summary Commodity Code':
			transactionDetails.summary_commodity_code,
		'Ach -> Billing -> Processor Id': transactionDetails.processor_id,
		'Ach -> Billing -> Custom Fields': transactionDetails.custom_fields,
		'Ach -> Billing -> Payment Method':
			transactionDetails.payment_method.token,
		// Billing address
		'Ach -> Billing Address -> First Name':
			transactionDetails.billing_address.first_name,
		'Ach -> Billing Address -> Last Name':
			transactionDetails.billing_address.last_name,
		'Ach -> Billing Address -> Country':
			transactionDetails.billing_address.country,
		'Ach -> Billing Address -> Address Line 1':
			transactionDetails.billing_address.address_line_1,
		'Ach -> Billing Address -> Address Line 2':
			transactionDetails.billing_address.address_line_2,
		'Ach -> Billing Address -> City':
			transactionDetails.billing_address.city,
		'Ach -> Billing Address -> State':
			transactionDetails.billing_address.state,
		'Ach -> Billing Address -> Postal Code':
			transactionDetails.billing_address.postal_code,
		'Ach -> Billing Address -> Email':
			transactionDetails.billing_address.email,
		'Ach -> Billing Address -> Company':
			transactionDetails.billing_address.company,
		'Ach -> Billing Address -> Phone':
			transactionDetails.billing_address.phone,
		'Ach -> Billing Address -> Fax': transactionDetails.billing_address.fax,
		// Shipping Address
		'Ach -> Billing Shipping Address -> First Name':
			transactionDetails.shipping_address.first_name,
		'Ach -> Billing Shipping Address -> Last Name':
			transactionDetails.shipping_address.last_name,
		'Ach -> Billing Shipping Address -> Company':
			transactionDetails.shipping_address.company,
		'Ach -> Billing Shipping Address -> Address Line 1':
			transactionDetails.shipping_address.address_line_1,
		'Ach -> Billing Shipping Address -> Address Line 2':
			transactionDetails.shipping_address.address_line_2,
		'Ach -> Billing Shipping Address -> City':
			transactionDetails.shipping_address.city,
		'Ach -> Billing Shipping Address -> State':
			transactionDetails.shipping_address.state,
		'Ach -> Billing Shipping Address -> Postal Code':
			transactionDetails.shipping_address.postal_code,
		'Ach -> Billing Shipping Address -> Country':
			transactionDetails.shipping_address.country,
		'Ach -> Billing Shipping Address -> Email':
			transactionDetails.shipping_address.email,
		'Ach -> Billing Shipping Address -> Phone':
			transactionDetails.shipping_address.phone,
		'Ach -> Billing Shipping Address -> Fax':
			transactionDetails.shipping_address.fax,
		// Response Body
		'Ach -> Response Body -> Sec Code':
			transactionDetails.response_body.ach.sec_code,
		'Ach -> Response Body -> Processor Response Code':
			transactionDetails.response_body.ach.processor_response_code,
		'Ach -> Response Body -> Processor Response Text':
			transactionDetails.response_body.ach.processor_response_text,
		'Ach Response Body -> Account Type':
			transactionDetails.response_body.ach.account_type,
		'Ach -> Response Body -> Processor Type':
			transactionDetails.response_body.ach.type,
		// Response body -> approved or decline
		'Ach -> Response Body -> Response':
			transactionDetails.response_body.ach.response,
		// Root
		'Ach -> Status': transactionDetails.status,
		'Ach -> Type': transactionDetails.type,
		'Ach -> Transaction Source': transactionDetails.transaction_source,
		'Ach -> Account Username': transactionDetails.user_name,
		'Ach -> Response Body -> Auth Code':
			transactionDetails.response_body.ach.response.auth_code,
		'Ach -> Transaction Id': transactionDetails.id,
		'Ach -> Timestamp': new Date(Date.now()).toLocaleString(),
		'Ach -> Transaction Amount': transactionDetails.amount_authorized,
		'Ach -> Response Code': transactionDetails.response_code
	})
}

// starts a timer when the user starts to fill out the form and clicks on the 'Full Name' field and compares it to when the submitTransaction
// event is called, giving us how long it takes to fill out the form
export function achFormTrackTime() {
	mixpanel.time_event('Submitted ACH Transaction')
}

// called when user views ach form and tracks it to the group the simplepay group
export function achFormPageViewTrack() {
	mixpanel.track('SimplePay ACH Form Viewed')
}

/***** : Events Properties *****/

// called when user views a inactive page
export function inactivePageViewTrack(simplePayId: string) {
	mixpanel.track('Inactive SimplePay Page Viewed', {
		'Inactive SimplePay Page': simplePayId
	})
}

/***** Page Not Found: Events Properties *****/

// called when user views ach form and tracks it to the group the simplepay group
export function pageNotFoundPageViewTrack(simplePayId: string) {
	mixpanel.track('Page Not Found SimplePay Page', {
		'None Existent Page': simplePayId
	})
}

/***** Login: Events *****/

// called when a anonymous user visit the login page before signing in
export function loginPageViewTrack() {
	mixpanel.track('Login')
}

/***** Admin: Events *****/

// called when a logged user views the admin list page
export function adminPageViewTrack() {
	mixpanel.track('Admin List Page Viewed')
}

// called after a user edits a page and tracks it as a event with the id of the page changed
export function adminEditTrack(simplePayId: string) {
	mixpanel.track('Admin Page Edited', {
		'Page Edited': simplePayId
	})
}
/***** After Payment: Events *****/

// called when a user is being redirected after a successful payment and bypasses success page
export function paymentSuccessAfterRedirectPageViewTrack() {
	mixpanel.track('User being redirect after transaction')
}

// called when a user made a successful payment
export function paymentSuccessPageViewTrack() {
	mixpanel.track('Payment Success View')
}

// called when a user clicks 'make another payment'
export function makeAnotherPayment() {
	mixpanel.track('Make Another Payment')
}
