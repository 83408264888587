import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { key, store } from './store'
import {
	ElAlert,
	ElAside,
	ElButton,
	ElCard,
	ElCheckbox,
	ElCheckboxGroup,
	ElCol,
	ElCollapse,
	ElCollapseItem,
	ElColorPicker,
	ElDatePicker,
	ElFooter,
	ElForm,
	ElFormItem,
	ElHeader,
	ElIcon,
	ElInput,
	ElLink,
	ElMain,
	ElOption,
	ElPopover,
	ElRow,
	ElRadio,
	ElRadioGroup,
	ElSelect,
	ElSwitch,
	ElTabPane,
	ElTable,
	ElTableColumn,
	ElTabs,
	ElUpload,
	ElMessage,
	ElMessageBox
} from 'element-plus'
import locale from 'element-plus/lib/locale/lang/en'
import 'element-plus/packages/theme-chalk/src/base.scss'
import '@/styles/styles.scss'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import mixpanel from 'mixpanel-browser'
import { captureException, init, setTag } from '@sentry/browser'

const components = [
	ElAlert,
	ElAside,
	ElButton,
	ElCard,
	ElCheckbox,
	ElCheckboxGroup,
	ElCol,
	ElCollapse,
	ElCollapseItem,
	ElColorPicker,
	ElDatePicker,
	ElFooter,
	ElForm,
	ElFormItem,
	ElHeader,
	ElIcon,
	ElInput,
	ElLink,
	ElMain,
	ElOption,
	ElPopover,
	ElRadio,
	ElRadioGroup,
	ElRow,
	ElSelect,
	ElSwitch,
	ElTabPane,
	ElTable,
	ElTableColumn,
	ElTabs,
	ElUpload
]

const plugins = [ElMessage, ElMessageBox]

const app = createApp(App)

components.forEach((component) => {
	app.component(component.name, component)
})

plugins.forEach((plugin) => {
	app.use(plugin)
})

app.use(store, key, locale).use(router).mount('#app')

if (
	process.env.VUE_APP_CLIENT_ENV === 'stage' ||
	process.env.VUE_APP_CLIENT_ENV === 'demo' ||
	process.env.VUE_APP_CLIENT_ENV === 'prod'
) {
	Sentry.init({
		dsn: process.env.VUE_APP_DSN,
		tracesSampleRate: 1,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.VUE_APP_SENTRY_ENV,
		tracingOptions: {
			trackComponents: true
		},
		release: 'simplepay-app@' + process.env.VUE_APP_VERSION,
		ignoreErrors: [
			'TypeError',
			'createError(axios/lib/core/createError)',
			'ResizeObserver loop limit exceeded',
			`Cannot read property '$el' of null`,
			'timeout of 0ms exceeded',
			`Cannot set property '__VUE_DEVTOOLS_IFRAME__' of null`,
			'Network Error',
			'Error createError',
			`SecurityError Error: Blocked a frame with origin "(<unknown module>)"`,
			'Blocked a frame with origin "https://simplepay.basysiqpro.com" from accessing a cross-origin frame.',
			'SecurityError: Blocked a frame with origin "https://simplepay.basysiqpro.com" from accessing a cross-origin frame.',
			'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
			'Object.i',
			'Non-Error promise rejection captured with value: Object Not Found Matching Id:1',
			'QuotaExceededError',
			'QuotaExceededError: The quota has been exceeded.'
		],
		blacklistUrls: [
			'https://dev-iqprosimplepay.herokuapp.com/',
			'https://test-iqprosimplepay.herokuapp.com/'
		]
	})
}

mixpanel.init(process.env.VUE_APP_MP_PROJECT_TOKEN, {
	cross_subdomain_cookie: true,
	loaded: function (mixpanel) {
		// get_distinct_id can only be called when mixpanel.init is called, which is suppose to be once
		app.config.globalProperties.$distinct_id = mixpanel.get_distinct_id()
		mixpanel.identify(app.config.globalProperties.$distinct_id)
		// sets default user information such as : browswer, session time, time zone, etc
		mixpanel.people.set({})
	}
})
app.provide('distinct_id', app.config.globalProperties.$distinct_id)

app.config.errorHandler = (error: any, _: any, info: any) => {
	setTag('info', info)
	captureException(error)
}
window.addEventListener('error', (event) => {
	Sentry.captureException(event)
})
window.addEventListener('unhandledrejection', (event) => {
	Sentry.captureException(event)
})
