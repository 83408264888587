import axios from 'axios'

let IqProApi: any

if (process.env.NODE_ENV === 'development') {
	IqProApi = axios.create({
		// for localhost use for development and local cypress testing
		// baseURL: 'https://simplepayapi-dev.iqpro.com',
		baseURL: process.env.VUE_APP_CYPRESS_IQPRO_URL,
		headers: {
			'Content-Type': 'application/json',
			// for localhost use for development and local cypress testing
			// 'Authorization': // get key from heroku
			Authorization: process.env.VUE_APP_CYPRESS_AUTHORIZATION
		}
	})
} else {
	IqProApi = axios.create({
		baseURL: process.env.VUE_APP_SERVER_URL,
		headers: {
			'Content-Type': 'application/json',
			Authorization: process.env.VUE_APP_IQPRO_APIKEY
		}
	})
}

export default {
	// utility endpoints that just pass through the server
	async simplepayWebhookPost(url: any, data: any): Promise<any> {
		return await IqProApi.post('/webhook', data, {
			headers: {
				webhook_uri: url
			}
		})
	},
	// endpoints that will only hit internal api
	async getSimplePay(simplePayId: string): Promise<any> {
		return await IqProApi.get('/api/posts/' + simplePayId)
	},
	async getAllSimplePay(): Promise<any> {
		return await IqProApi.get('/api/posts')
	},
	async createSimplePay(createData: any): Promise<any> {
		return await IqProApi.post('/api/posts', createData)
	},
	async updateSimplePay(simplePayId: string, updateData: any): Promise<any> {
		return await IqProApi.put('/api/posts/' + simplePayId, updateData)
	},
	// endpoints that will also call FP API
	async getLoginToken(loginCredentials: string): Promise<any> {
		return IqProApi.post('/api/auth', loginCredentials)
	},
	async logOut(loginToken: string): Promise<any> {
		return IqProApi.get('/api/auth/logout', {
			params: {
				Authorization: loginToken
			}
		})
	},
	async getSimplePayInitSettings(payload: any): Promise<any> {
		return IqProApi.get('/api/simplepay/' + payload.simplePayId, {
			params: {
				distinctId: payload.distinctId
			}
		})
	},
	async postTransaction(payload: any): Promise<any> {
		return IqProApi.post(
			'/api/simplepay/transaction',
			payload.requestBody,
			{
				params: {
					id: payload.simplePayId,
					token: payload.grecaptchaToken,
					distinctId: payload.distinctId
				}
			}
		)
	},
	async getCountries(simplePayId: any): Promise<any> {
		return IqProApi.get('/api/location/country', {
			params: {
				id: simplePayId
			}
		})
	},
	async getStates(payload: any): Promise<any> {
		return IqProApi.get('/api/location/state', {
			params: {
				country: payload.country,
				id: payload.simplePayId
			}
		})
	}
}
